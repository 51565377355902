/* ScanningAnimation.css */

.scanning-container {
  position: relative;
  display: inline-block;
}

.scanned-image {
  display: block;
  width: 100%;
  height: auto;
}

.scanning-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #92c4ca;
  animation: scan 5s infinite alternate;
}

@keyframes scan {
  0% {
    top: -0;
  }
  100% {
    top: 100%;
  }
}
