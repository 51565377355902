.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.prompt-area {
  width: 39rem;
  border: 1px white solid;
  border-radius: 10px;
}

.response {
  color: white;
}

.dropzone {
  width: 60%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}
.input-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.details {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.details-text {
  font-size: medium;
  margin: 0 5px;
}
.analyze-button {
  padding: 1rem 2rem;
  font-size: large;
  border-radius: 1rem;
}

#photo-container {
  position: relative;
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
}

.photo {
  position: absolute;
  object-fit: cover;
  transition: transform 0.3s, opacity 0.3s;
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
img {
  height: 10rem;
}

.photo:nth-child(1) {
  top: 0;
  left: 0;
  z-index: 5;
}

.photo:nth-child(2) {
  top: 10px;
  left: 10px;
  z-index: 4;
}

.photo:nth-child(3) {
  top: 20px;
  left: 20px;
  z-index: 3;
}

.photo:nth-child(4) {
  top: 30px;
  left: 30px;
  z-index: 2;
}

.photo:nth-child(5) {
  top: 40px;
  left: 40px;
  z-index: 1;
}

.photo:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  padding: 3px 6px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50%;
  display: none;
}

.photo:hover .delete-button {
  display: block;
}

.response {
  text-align: left;
  width: 75%;
  font-size: medium;
  margin: 0 auto;
}

.max-h-container {
  max-height: 70dvh; /* Adjust this value as needed */
  overflow-y: auto;
}
