:root {
  --light-gradient: linear-gradient(
    135deg,
    #e0f7fa 0%,
    #fff1f0 50%,
    #e0f7fa 100%
  );
  --dark-gradient: linear-gradient(
    135deg,
    #263238 0%,
    #37474f 50%,
    #263238 100%
  );
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  background-attachment: fixed;
  background-image: var(--light-gradient);
  background-size: 400% 400%;
  transition: background 0.3s ease;
  animation: gradientAnimation 15s ease infinite;
}

body.dark {
  background-image: var(--dark-gradient);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
