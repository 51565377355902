.custom-dots {
  position: absolute;
  bottom: -30px;
  display: flex !important;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.custom-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.custom-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.custom-dots li button:before {
  font-size: 12px;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
}

.custom-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
